body {
  height: 100%;
  background-color: rgba(1, 105, 217) !important;
}
.LoginForm {
  overflow: auto;
  background-color: rgba(1, 105, 217);
  color: white;
  position: relative;
  top: 0;
  bottom: 0;   
  height: 100%;
  width: 100%;
  padding: 15px;
}


