body {
    height: 100%;
    background-color: rgba(1, 105, 217) !important;
  }
  .Body {
    overflow: auto;
    background-color: rgba(242, 240, 233);
    color: black;
    position: relative;
    top: 0;
    bottom: 0;   
    width: 100%;
    padding: 15px;
    height: 100vh;
  }
  .MenuButtonContainer{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MenuButton{
    background-color: rgba(1, 105, 217);
    color: white;
    min-width: 200px;
  }
  .FlashButton{
    background-color: rgba(0, 0, 220);
    color: white;
    width: 3em;
    border-radius: 40%;
  }
  .CancelButton{
    background-color: rgba(90, 90, 90);
    color: white;
    min-width: 6em;
  }
  .PictureMiniature{
    max-height: 100px;
  }

  
  